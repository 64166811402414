import React from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Timeline, Tween } from "react-gsap"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { graphql, StaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Fade } from "react-awesome-reveal"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

function ProjectsPage(props) {
  const { site } = props.data
  const { siteMetadata } = site
  const { title, author, strapiUrl, siteUrl } = siteMetadata
  return (
    <Layout
      duration={"0"}
      delay={"0"}
      title={title}
      strapiUrl={strapiUrl}
      logo={`${strapiUrl}/uploads/bitlight_logo_W_3be48e8889.png`}
      backgroundColor={"#24272A"}
      siteUrl={siteUrl}
      textColor={"#ffffff"}
    >
      <Seo
        title={"Contact"}
        keywords={[
          "bitlight",
          "projects",
          "mobileapps",
          "mobile",
          "app",
          "apps",
          "web",
          "webapps",
        ]}
      />
      <Controller>
        <Scene triggerHook={"onLeave"} duration={"300%"} pin>
          <Timeline
            wrapper={<div id={"pinContainer"} />}
            target={
              <>
                <Box
                  backgroundImage={"url('background.png')"}
                  key={"1"}
                  className={"panel"}
                >
                  <Fade
                    duration={1000}
                    direction={"down"}
                    style={{ height: "100%" }}
                    triggerOnce={true}
                  >
                    <Flex
                      flexDir={"row"}
                      alignSelf={"center"}
                      height={"full"}
                      p={"150px"}
                      // justifyContent={"center"}
                      // alignContent={"center"}
                      // alignItems={"center"}
                    >
                      <Flex
                        flexDirection={"column"}
                        flex={0.33}
                        alignSelf={"center"}
                        textAlign={"left"}
                        justifyContent={"flex-start"}
                      >
                        <Heading
                          fontFamily={"GT Walsheim"}
                          fontSize={"36px"}
                          lineHeight={"40px"}
                          fontWeight={"700"}
                        >
                          Looking for a job?
                        </Heading>
                        <Text
                          fontFamily={"GT Walsheim"}
                          fontSize={"20px"}
                          lineHeight={"22px"}
                          fontWeight={"700"}
                          my={"15px"}
                        >
                          Pogledajte naše trenutno otvorene ponude za posao. Ako
                          ne pronalazite ono čime se vi bavite, možete nam
                          poslati otvorenu prijavu!
                        </Text>
                        <Flex
                          px={"30px"}
                          py={"15px"}
                          backgroundColor={"#24272A"}
                          w={"fit-content"}
                          borderRadius={"16px"}
                          my={"15px"}
                        >
                          <AniLink
                            id={"contactButton"}
                            paintDrip
                            to={"/contact"}
                            hex={"#ffd600"}
                            style={{
                              color: "white",
                            }}
                          >
                            <Text fontFamily={"GT Walsheim"} color={"white"}>
                              Take a Look!
                            </Text>
                          </AniLink>
                        </Flex>
                      </Flex>
                      <Box
                        backgroundImage={"url('mobiteli_compressed.png')"}
                        backgroundSize={"contain"}
                        backgroundRepeat={"no-repeat"}
                        flex={0.67}
                      />
                    </Flex>
                  </Fade>
                </Box>
                <div key={"2"} className={"panel bordeaux"}>
                  <span>Panel 2</span>
                </div>
                <div key={"3"} className={"panel green"}>
                  <span>Panel 2</span>
                </div>
                <div key={"4"} className={"panel turqoise"}>
                  <span>Panel 2</span>
                </div>
              </>
            }
          >
            <>
              <Tween to={{ scale: 0.8 }} target={0} position={0} />
              <Tween to={{ x: "-100%" }} target={0} position={1} />
              <Tween
                from={{ x: "100%", scale: 0.8 }}
                to={{ x: "0%", scale: 0.8 }}
                target={1}
                position={1}
              />
              <Tween
                from={{ scale: 0.8 }}
                to={{ scale: 1 }}
                target={1}
                position={2}
              />
              <Tween
                from={{ scale: 1 }}
                to={{ scale: 0.8 }}
                target={1}
                position={3}
              />

              <Tween to={{ x: "-100%" }} target={1} position={4} />
              <Tween
                from={{ x: "100%", scale: 0.8 }}
                to={{ x: "0%", scale: 0.8 }}
                target={2}
                position={4}
              />
              <Tween
                from={{ scale: 0.8 }}
                to={{ scale: 1 }}
                target={2}
                position={5}
              />
              <Tween
                from={{ scale: 1 }}
                to={{ scale: 0.8 }}
                target={2}
                position={6}
              />

              <Tween to={{ x: "-100%" }} target={2} position={7} />
              <Tween
                from={{ x: "100%", scale: 0.8 }}
                to={{ x: "0%", scale: 0.8 }}
                target={3}
                position={7}
              />
              <Tween
                from={{ scale: 0.8 }}
                to={{ scale: 1 }}
                target={3}
                position={8}
              />
            </>
          </Timeline>
        </Scene>
      </Controller>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        strapiUrl
        siteUrl
      }
    }
  }
`

function StaticProjectsPage(props) {
  return (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <ProjectsPage location={props.location} data={data} {...props} />
      )}
    />
  )
}

function ProjectPanel() {
  return (
    <Fade
      duration={1000}
      delay={200}
      distance={"30px"}
      style={{ backgroundImage: "url('background.png')" }}
    >
      <h1 id={"contact-hero-title"}>
        We turn your thoughts and ideas into awesome digital products.
      </h1>
      <br />
      <h1 id={"contact-hero-subtitle"} className={"hero-contact-us"}>
        Seeing as you&apos;re interested in the digital revolution, why not take
        the first step and reach out to us?
      </h1>
      <div className={"contact-us"}>
        <AniLink
          id={"contactButton"}
          paintDrip
          to={"/contact"}
          hex={"#ffd600"}
          style={{
            flex: 1,
            zIndex: 10000,
          }}
        >
          Contact
        </AniLink>
      </div>
    </Fade>
  )
}

export default StaticProjectsPage
